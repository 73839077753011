import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Comercial = () =>{
  const collage = [
    { image: "/images/comercial/pan1.webp", category: "events" },
    { image: "/images/comercial/pan2.webp", category: "events" },
    { image: "/images/comercial/pan3.webp", category: "events" },
    { image: "/images/comercial/pan4.webp", category: "events" },
    { image: "/images/comercial/pan5.webp", category: "events" },
    { image: "/images/comercial/pan6.webp", category: "events" },
    { image: "/images/comercial/pan7.webp", category: "events" },
    { image: "/images/comercial/pan8.webp", category: "events" },
    { image: "/images/comercial/pan9.webp", category: "events" },
    { image: "/images/comercial/pan10.webp", category: "events" },
    { image: "/images/comercial/pan11.webp", category: "events" },
    { image: "/images/comercial/pan12.webp", category: "events" },
    { image: "/images/comercial/pan13.webp", category: "events" },
    { image: "/images/comercial/pan14.webp", category: "events" },
    { image: "/images/comercial/pan15.webp", category: "events" },
    { image: "/images/comercial/pan16.webp", category: "events" },
    { image: "/images/comercial/pan17.webp", category: "events" },
    { image: "/images/comercial/pan18.webp", category: "events" },
    { image: "/images/comercial/pan19.webp", category: "events" },
    { image: "/images/comercial/pan20.webp", category: "events" },
    { image: "/images/comercial/pan21.webp", category: "events" },
    { image: "/images/comercial/pan22.webp", category: "events" },
    { image: "/images/comercial/pan23.webp", category: "events" },
    { image: "/images/comercial/pan24.webp", category: "events" },
    { image: "/images/comercial/pan25.webp", category: "events" },
    { image: "/images/comercial/pan26.webp", category: "events" },
    { image: "/images/comercial/pan27.webp", category: "events" },
    { image: "/images/comercial/pan28.webp", category: "events" },
    { image: "/images/comercial/pan29.webp", category: "events" },
    { image: "/images/comercial/pan30.webp", category: "events" },
    { image: "/images/comercial/pan31.webp", category: "events" },
    { image: "/images/comercial/pan32.webp", category: "events" },
    { image: "/images/comercial/pan33.webp", category: "events" },
    { image: "/images/comercial/pan34.webp", category: "events" },
    { image: "/images/comercial/pan35.webp", category: "events" },
    { image: "/images/comercial/pan36.webp", category: "events" },
    { image: "/images/comercial/pan37.webp", category: "events" },
    { image: "/images/comercial/pan38.webp", category: "events" },
    { image: "/images/comercial/pan39.webp", category: "events" },
    { image: "/images/comercial/pan40.webp", category: "events" },
    { image: "/images/comercial/pan41.webp", category: "events" },
    { image: "/images/comercial/pan42.webp", category: "events" },
    { image: "/images/comercial/pan43.webp", category: "events" },
    { image: "/images/comercial/pan44.webp", category: "events" },
    { image: "/images/comercial/pan45.webp", category: "events" },
    { image: "/images/comercial/pan46.webp", category: "events" },
    { image: "/images/comercial/pan47.webp", category: "events" },
    { image: "/images/comercial/pan48.webp", category: "events" },
    { image: "/images/comercial/pan49.webp", category: "events" },
    { image: "/images/comercial/pan50.webp", category: "events" },
    { image: "/images/comercial/pan51.webp", category: "events" },
    { image: "/images/comercial/pan52.webp", category: "events" },
    { image: "/images/comercial/pan53.webp", category: "events" },
    { image: "/images/comercial/pan54.webp", category: "events" },
    { image: "/images/comercial/pan55.webp", category: "events" },
    { image: "/images/comercial/pan56.webp", category: "events" },
    { image: "/images/comercial/pan57.webp", category: "events" },
    { image: "/images/comercial/pan58.webp", category: "events" },
    { image: "/images/comercial/pan59.webp", category: "events" },
    { image: "/images/comercial/pan60.webp", category: "events" },
    { image: "/images/comercial/pan61.webp", category: "events" },
    { image: "/images/comercial/pan62.webp", category: "events" },
    { image: "/images/comercial/pan63.webp", category: "events" }
 
];

      return(
          <>
        
              <Portfolio collage={collage}  title="COMERCIAL WORK" text="Explore our portfolio of commercial photography, featuring high-impact images that elevate brands and products with professional, eye-catching visuals."/>
              <Slider/>
              </>
      )
  }
  

export default Comercial;