import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Maternity = () =>{
  const collage = [
    { image: "/images/maternity/mat1.webp", category: "maternity" },
    { image: "/images/maternity/mat2.webp", category: "maternity" },
    { image: "/images/maternity/mat3.webp", category: "maternity" },
    { image: "/images/maternity/mat4.webp", category: "maternity" },
    { image: "/images/maternity/mat5.webp", category: "maternity" },
    { image: "/images/maternity/mat6.webp", category: "maternity" },

  
 
];

      return(
          <>
        
              <Portfolio collage={collage}  title="Maternity Moments" text="Celebrate the beauty of pregnancy with our heartfelt maternity photography, capturing the joy and anticipation of expecting mothers in elegant and touching portraits."/>
              <Slider/>
              </>
      )
  }
  

export default Maternity;