import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import style from "./Portfolio.module.css"
import LazyLoad from "react-lazyload"

const Portfolio = (props) =>{
    useEffect(() => {
        AOS.init();
      }, []);
    return(
       <>
     <div data-aos="fade-up">
          <div className={style.portfolioHeading}>
        <h2>{props.title}</h2>
        <p>{props.text}</p>
        </div>
        </div>
        <div data-aos="fade-up">
        <div className={style.portfolioDis}>
            {props.collage.map(((item,index) =>{
                return(
          
                    <LazyLoad height={200} offset={100}   className={style.portfolioDisImg} key={index}>
                    <a href="https://www.instagram.com/medirushiti/" target="_blank"  rel="noreferrer">
                      <img src={item.image} className="w-100" width="100%" alt="ig"/>
                    </a>
                    </LazyLoad>
                    

                )
            }))}
        </div>
        </div>
       </>
    )
}

export default Portfolio;