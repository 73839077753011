import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Kids = () =>{
  const collage = [
    { image: "/images/kids/kid1.webp" },
    { image: "/images/kids/kid2.webp" },
    { image: "/images/kids/kid3.webp" },
    { image: "/images/kids/kid4.webp" },
    { image: "/images/kids/kid55.webp" },
    { image: "/images/kids/kid56.webp" },
    { image: "/images/kids/kid5.webp" },
    { image: "/images/kids/kid6.webp" },
    { image: "/images/kids/kid7.webp" },
    { image: "/images/kids/kid8.webp" },
    { image: "/images/kids/kid9.webp" },
    { image: "/images/kids/kid10.webp" },
    { image: "/images/kids/kid11.webp" },
    { image: "/images/kids/kid12.webp" },
    { image: "/images/kids/kid13.webp" },
    { image: "/images/kids/kid14.webp" },
    { image: "/images/kids/kid15.webp" },
    { image: "/images/kids/kid16.webp" },
    { image: "/images/kids/kid17.webp" },
    { image: "/images/kids/kid18.webp" },
    { image: "/images/kids/kid19.webp" },
    { image: "/images/kids/kid20.webp" },
    { image: "/images/kids/kid21.webp" },
    { image: "/images/kids/kid22.webp" },
    { image: "/images/kids/kid23.webp" },
    { image: "/images/kids/kid24.webp" },
    { image: "/images/kids/kid25.webp" },
    { image: "/images/kids/kid26.webp" },
    { image: "/images/kids/kid27.webp" },
    { image: "/images/kids/kid28.webp" },
    { image: "/images/kids/kid29.webp" },
    { image: "/images/kids/kid30.webp" },
    { image: "/images/kids/kid31.webp" },
    { image: "/images/kids/kid32.webp" },
    { image: "/images/kids/kid33.webp" },
    { image: "/images/kids/kid34.webp" },
    { image: "/images/kids/kid35.webp" },
    { image: "/images/kids/kid36.webp" },
    { image: "/images/kids/kid37.webp" },
    { image: "/images/kids/kid38.webp" },
    { image: "/images/kids/kid39.webp" },
    { image: "/images/kids/kid40.webp" },
    { image: "/images/kids/kid41.webp" },
    { image: "/images/kids/kid42.webp" },
    { image: "/images/kids/kid43.webp" },
    { image: "/images/kids/kid44.webp" },
    { image: "/images/kids/kid45.webp" },
    { image: "/images/kids/kid46.webp" },
    { image: "/images/kids/kid47.webp" },
    { image: "/images/kids/kid48.webp" },
    { image: "/images/kids/kid49.webp" },
    { image: "/images/kids/kid50.webp" },
    { image: "/images/kids/kid51.webp" },
    { image: "/images/kids/kid52.webp" },
    { image: "/images/kids/kid53.webp" },
    { image: "/images/kids/kid54.webp" },
    { image: "/images/kids/kid50.webp" },
];

      return(
          <>
        
              <Portfolio collage={collage}  title="KIDS' MOMENTS" text="From giggles to curious glances, I specialize in capturing the joy and innocence of childhood moments. Let me freeze-frame those precious smiles and playful adventures that you'll cherish forever."






/>
              <Slider/>
              </>
      )
  }
  

export default Kids;