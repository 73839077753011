import React, { useState ,useEffect} from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
import style from './Collage.module.css'; // Replace with your CSS module
import Slider from '../slider/slider';
import LazyLoad from 'react-lazyload';

const YourComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState('All');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const collage = [
    { image: "/images/modeling/model49.webp", category: "lifestyle" },
    { image: "/images/modeling/model1.webp", category: "lifestyle" },
    { image: "/images/modeling/model2.webp", category: "lifestyle" },
    { image: "/images/modeling/model3.webp", category: "lifestyle" },
    { image: "/images/modeling/model4.webp", category: "lifestyle" },
    { image: "/images/modeling/model5.webp", category: "lifestyle" },
    { image: "/images/modeling/model6.webp", category: "lifestyle" },
    { image: "/images/modeling/model7.webp", category: "lifestyle" },
    { image: "/images/modeling/model8.webp", category: "lifestyle" },
    { image: "/images/modeling/model9.webp", category: "lifestyle" },
    { image: "/images/modeling/model10.webp", category: "lifestyle" },
    { image: "/images/modeling/model11.webp", category: "lifestyle" },
    { image: "/images/modeling/model12.webp", category: "lifestyle" },
    { image: "/images/modeling/model13.webp", category: "lifestyle" },
    { image: "/images/modeling/model14.webp", category: "lifestyle" },
    { image: "/images/modeling/model15.webp", category: "lifestyle" },
    { image: "/images/modeling/model16.webp", category: "lifestyle" },
    { image: "/images/modeling/model17.webp", category: "lifestyle" },
    { image: "/images/modeling/model18.webp", category: "lifestyle" },
    { image: "/images/modeling/model19.webp", category: "lifestyle" },
    { image: "/images/modeling/model20.webp", category: "lifestyle" },
    { image: "/images/modeling/model21.webp", category: "lifestyle" },
    { image: "/images/modeling/model22.webp", category: "lifestyle" },
    { image: "/images/modeling/model23.webp", category: "lifestyle" },
    { image: "/images/modeling/model24.webp", category: "lifestyle" },
    { image: "/images/modeling/model25.webp", category: "lifestyle" },
    { image: "/images/modeling/model26.webp", category: "lifestyle" },
    { image: "/images/modeling/model27.webp", category: "lifestyle" },
    { image: "/images/modeling/model28.webp", category: "lifestyle" },
    { image: "/images/modeling/model29.webp", category: "lifestyle" },
    { image: "/images/modeling/model30.webp", category: "lifestyle" },
    { image: "/images/modeling/model31.webp", category: "lifestyle" },
    { image: "/images/modeling/model32.webp", category: "lifestyle" },
    { image: "/images/modeling/model33.webp", category: "lifestyle" },
    { image: "/images/modeling/model34.webp", category: "lifestyle" },
    { image: "/images/modeling/model35.webp", category: "lifestyle" },
    { image: "/images/modeling/model36.webp", category: "lifestyle" },
    { image: "/images/modeling/model37.webp", category: "lifestyle" },
    { image: "/images/modeling/model38.webp", category: "lifestyle" },
    { image: "/images/modeling/model39.webp", category: "lifestyle" },
    { image: "/images/modeling/model40.webp", category: "lifestyle" },
    { image: "/images/modeling/model41.webp", category: "lifestyle" },
    { image: "/images/modeling/model42.webp", category: "lifestyle" },
    { image: "/images/modeling/model43.webp", category: "lifestyle" },
    { image: "/images/modeling/model44.webp", category: "lifestyle" },
    { image: "/images/modeling/model45.webp", category: "lifestyle" },
    { image: "/images/modeling/model46.webp", category: "lifestyle" },
    { image: "/images/modeling/model47.webp", category: "lifestyle" },
    { image: "/images/weddings/wedp1.webp", category: "wedding" },
    { image: "/images/weddings/wedp2.webp", category: "wedding" },
    { image: "/images/weddings/wedp3.webp", category: "wedding" },
    { image: "/images/weddings/wedp4.webp", category: "wedding" },
    { image: "/images/weddings/wedp5.webp", category: "wedding" },
    { image: "/images/weddings/wedp6.webp", category: "wedding" },
    { image: "/images/weddings/wedp7.webp", category: "wedding" },
    { image: "/images/weddings/wedp8.webp", category: "wedding" },
    { image: "/images/weddings/wedp9.webp", category: "wedding" },
    { image: "/images/weddings/wedp10.webp", category: "wedding" },
    { image: "/images/weddings/wedp11.webp", category: "wedding" },
    { image: "/images/weddings/wedp12.webp", category: "wedding" },
    { image: "/images/weddings/wedp13.webp", category: "wedding" },
    { image: "/images/weddings/wedp14.webp", category: "wedding" },
    { image: "/images/weddings/wedp15.webp", category: "wedding" },
    { image: "/images/weddings/wedp16.webp", category: "wedding" },
    { image: "/images/weddings/wedp17.webp", category: "wedding" },
    { image: "/images/weddings/wedp18.webp", category: "wedding" },
    { image: "/images/weddings/wedp19.webp", category: "wedding" },
    { image: "/images/weddings/wedp20.webp", category: "wedding" },
    { image: "/images/weddings/wedp21.webp", category: "wedding" },
    { image: "/images/weddings/wedp22.webp", category: "wedding" },
    { image: "/images/weddings/wedp23.webp", category: "wedding" },
    { image: "/images/weddings/wedp24.webp", category: "wedding" },
    { image: "/images/weddings/wedp25.webp", category: "wedding" },
    { image: "/images/weddings/wedp26.webp", category: "wedding" },
    { image: "/images/weddings/wedp27.webp", category: "wedding" },
    { image: "/images/weddings/wedp28.webp", category: "wedding" },
    { image: "/images/weddings/wedp29.webp", category: "wedding" },
    { image: "/images/weddings/wedp30.webp", category: "wedding" },
    { image: "/images/weddings/wedp31.webp", category: "wedding" },
    { image: "/images/weddings/wedp32.webp", category: "wedding" },
    { image: "/images/weddings/wedp33.webp", category: "wedding" },
    { image: "/images/weddings/wedp34.webp", category: "wedding" },
    { image: "/images/weddings/wedp35.webp", category: "wedding" },
    { image: "/images/weddings/wedp36.webp", category: "wedding" },
    { image: "/images/kids/kid1.webp", category: "kids" },
    { image: "/images/kids/kid2.webp", category: "kids" },
    { image: "/images/kids/kid3.webp", category: "kids" },
    { image: "/images/kids/kid4.webp", category: "kids" },
    { image: "/images/kids/kid55.webp", category: "kids" },
    { image: "/images/kids/kid56.webp", category: "kids" },
    { image: "/images/kids/kid5.webp", category: "kids" },
    { image: "/images/kids/kid6.webp", category: "kids" },
    { image: "/images/kids/kid7.webp", category: "kids" },
    { image: "/images/kids/kid8.webp", category: "kids" },
    { image: "/images/kids/kid9.webp", category: "kids" },
    { image: "/images/kids/kid10.webp", category: "kids" },
    { image: "/images/kids/kid11.webp", category: "kids" },
    { image: "/images/kids/kid12.webp", category: "kids" },
    { image: "/images/kids/kid13.webp", category: "kids" },
    { image: "/images/kids/kid14.webp", category: "kids" },
    { image: "/images/kids/kid15.webp", category: "kids" },
    { image: "/images/kids/kid16.webp", category: "kids" },
    { image: "/images/kids/kid17.webp", category: "kids" },
    { image: "/images/kids/kid18.webp", category: "kids" },
    { image: "/images/kids/kid19.webp", category: "kids" },
    { image: "/images/kids/kid20.webp", category: "kids" },
    { image: "/images/kids/kid21.webp", category: "kids" },
    { image: "/images/kids/kid22.webp", category: "kids" },
    { image: "/images/kids/kid23.webp", category: "kids" },
    { image: "/images/kids/kid24.webp", category: "kids" },
    { image: "/images/kids/kid25.webp", category: "kids" },
    { image: "/images/kids/kid26.webp", category: "kids" },
    { image: "/images/kids/kid27.webp", category: "kids" },
    { image: "/images/kids/kid28.webp", category: "kids" },
    { image: "/images/kids/kid29.webp", category: "kids" },
    { image: "/images/kids/kid30.webp", category: "kids" },
    { image: "/images/kids/kid31.webp", category: "kids" },
    { image: "/images/kids/kid32.webp", category: "kids" },
    { image: "/images/kids/kid33.webp", category: "kids" },
    { image: "/images/kids/kid34.webp", category: "kids" },
    { image: "/images/kids/kid35.webp", category: "kids" },
    { image: "/images/kids/kid36.webp", category: "kids" },
    { image: "/images/kids/kid37.webp", category: "kids" },
    { image: "/images/kids/kid38.webp", category: "kids" },
    { image: "/images/kids/kid39.webp", category: "kids" },
    { image: "/images/kids/kid40.webp", category: "kids" },
    { image: "/images/kids/kid41.webp", category: "kids" },
    { image: "/images/kids/kid42.webp", category: "kids" },
    { image: "/images/kids/kid43.webp", category: "kids" },
    { image: "/images/kids/kid44.webp", category: "kids" },
    { image: "/images/kids/kid45.webp", category: "kids" },
    { image: "/images/kids/kid46.webp", category: "kids" },
    { image: "/images/kids/kid47.webp", category: "kids" },
    { image: "/images/kids/kid48.webp", category: "kids" },
    { image: "/images/kids/kid49.webp", category: "kids" },
    { image: "/images/kids/kid50.webp", category: "kids" },
    { image: "/images/kids/kid51.webp", category: "kids" },
    { image: "/images/kids/kid52.webp", category: "kids" },
    { image: "/images/kids/kid53.webp", category: "kids" },
    { image: "/images/kids/kid54.webp", category: "kids" },
    { image: "/images/kids/kid50.webp", category: "kids" },
    { image: "/images/cars/car1.webp", category: "cars" },
    { image: "/images/cars/car2.webp", category: "cars" },
    { image: "/images/cars/car3.webp", category: "cars" },
    
    { image: "/images/cars/car5.webp", category: "cars" },
    { image: "/images/cars/car6.webp", category: "cars" },
    { image: "/images/cars/car7.webp", category: "cars" },
    { image: "/images/cars/car8.webp", category: "cars" },
    { image: "/images/cars/car9.webp", category: "cars" },
    { image: "/images/cars/car10.webp", category: "cars" },
    { image: "/images/cars/car11.webp", category: "cars" },
    { image: "/images/cars/car12.webp", category: "cars" },
    { image: "/images/cars/car13.webp", category: "cars" },
    { image: "/images/cars/car14.webp", category: "cars" },
  
    { image: "/images/cars/car16.webp", category: "cars" },
    { image: "/images/cars/car17.webp", category: "cars" },
    { image: "/images/cars/car18.webp", category: "cars" },
    { image: "/images/cars/car19.webp", category: "cars" },
    { image: "/images/cars/car15.webp", category: "cars" },
    { image: "/images/cars/car4.webp", category: "cars" },
    { image: "/images/nightlife/night1.webp", category: "nightlife" },
    { image: "/images/nightlife/night2.webp", category: "nightlife" },
    { image: "/images/nightlife/night3.webp", category: "nightlife" },
    { image: "/images/nightlife/night4.webp", category: "nightlife" },
    { image: "/images/nightlife/night5.webp", category: "nightlife" },
    { image: "/images/nightlife/night6.webp", category: "nightlife" },
    { image: "/images/nightlife/night7.webp", category: "nightlife" },
    { image: "/images/nightlife/night8.webp", category: "nightlife" },
    { image: "/images/nightlife/night9.webp", category: "nightlife" },
    { image: "/images/nightlife/night10.webp", category: "nightlife" },
    { image: "/images/nightlife/night11.webp", category: "nightlife" },
    { image: "/images/nightlife/night12.webp", category: "nightlife" },
    { image: "/images/nightlife/night13.webp", category: "nightlife" },
    { image: "/images/nightlife/night14.webp", category: "nightlife" },
    { image: "/images/nightlife/night15.webp", category: "nightlife" },
    { image: "/images/nightlife/night16.webp", category: "nightlife" },
    { image: "/images/nightlife/night17.webp", category: "nightlife" },
    { image: "/images/nightlife/night18.webp", category: "nightlife" },
    { image: "/images/nightlife/night19.webp", category: "nightlife" },
    { image: "/images/nightlife/night20.webp", category: "nightlife" },
    { image: "/images/nightlife/night21.webp", category: "nightlife" },
    { image: "/images/nightlife/night22.webp", category: "nightlife" },
    { image: "/images/nightlife/night23.webp", category: "nightlife" },
    { image: "/images/maternity/mat1.webp", category: "maternity" },
    { image: "/images/maternity/mat2.webp", category: "maternity" },
    { image: "/images/maternity/mat3.webp", category: "maternity" },
    { image: "/images/maternity/mat4.webp", category: "maternity" },
    { image: "/images/maternity/mat5.webp", category: "maternity" },
    { image: "/images/maternity/mat6.webp", category: "maternity" },
    { image: "/images/maternity/mat7.webp", category: "maternity" },
    { image: "/images/spaces/event1.webp", category: "spaces" },
    { image: "/images/spaces/event2.webp", category: "spaces" },
  
    { image: "/images/spaces/event4.webp", category: "spaces" },
    { image: "/images/spaces/event5.webp", category: "spaces" },
    { image: "/images/spaces/event3.webp", category: "spaces" },
    
    { image: "/images/spaces/event7.webp", category: "spaces" },
    { image: "/images/spaces/event8.webp", category: "spaces" },
    { image: "/images/spaces/event9.webp", category: "spaces" },
    { image: "/images/spaces/event10.webp", category: "spaces" },
    { image: "/images/spaces/event11.webp", category: "spaces" },
    { image: "/images/spaces/event17.webp", category: "spaces" },
    { image: "/images/spaces/event13.webp", category: "spaces" },
    { image: "/images/spaces/event14.webp", category: "spaces" },
    { image: "/images/spaces/event15.webp", category: "spaces" },
    { image: "/images/spaces/event6.webp", category: "spaces" },
    { image: "/images/spaces/event16.webp", category: "spaces" },
  
    { image: "/images/spaces/event12.webp", category: "spaces" },
    { image: "/images/spaces/event18.webp", category: "spaces" },
    { image: "/images/spaces/event22.webp", category: "spaces" },
    
    { image: "/images/spaces/event20.webp", category: "spaces" },
    { image: "/images/spaces/event19.webp", category: "spaces" },
    
    { image: "/images/spaces/event23.webp", category: "spaces" },
    { image: "/images/spaces/event24.webp", category: "spaces" },
    { image: "/images/comercial/pan1.webp", category: "events" },
    { image: "/images/comercial/pan2.webp", category: "events" },
    { image: "/images/comercial/pan3.webp", category: "events" },
    { image: "/images/comercial/pan4.webp", category: "events" },
    { image: "/images/comercial/pan5.webp", category: "events" },
    { image: "/images/comercial/pan6.webp", category: "events" },
    { image: "/images/comercial/pan7.webp", category: "events" },
    { image: "/images/comercial/pan8.webp", category: "events" },
    { image: "/images/comercial/pan9.webp", category: "events" },
    { image: "/images/comercial/pan10.webp", category: "events" },
    { image: "/images/comercial/pan11.webp", category: "events" },
    { image: "/images/comercial/pan12.webp", category: "events" },
    { image: "/images/comercial/pan13.webp", category: "events" },
    { image: "/images/comercial/pan14.webp", category: "events" },
    { image: "/images/comercial/pan15.webp", category: "events" },
    { image: "/images/comercial/pan16.webp", category: "events" },
    { image: "/images/comercial/pan17.webp", category: "events" },
    { image: "/images/comercial/pan18.webp", category: "events" },
    { image: "/images/comercial/pan19.webp", category: "events" },
    { image: "/images/comercial/pan20.webp", category: "events" },
    { image: "/images/comercial/pan21.webp", category: "events" },
    { image: "/images/comercial/pan22.webp", category: "events" },
    { image: "/images/comercial/pan23.webp", category: "events" },
    { image: "/images/comercial/pan24.webp", category: "events" },
    { image: "/images/comercial/pan25.webp", category: "events" },
    { image: "/images/comercial/pan26.webp", category: "events" },
    { image: "/images/comercial/pan27.webp", category: "events" },
    { image: "/images/comercial/pan28.webp", category: "events" },
    { image: "/images/comercial/pan29.webp", category: "events" },
    { image: "/images/comercial/pan30.webp", category: "events" },
    { image: "/images/comercial/pan31.webp", category: "events" },
    { image: "/images/comercial/pan32.webp", category: "events" },
    { image: "/images/comercial/pan33.webp", category: "events" },
    { image: "/images/comercial/pan34.webp", category: "events" },
    { image: "/images/comercial/pan35.webp", category: "events" },
    { image: "/images/comercial/pan36.webp", category: "events" },
    { image: "/images/comercial/pan37.webp", category: "events" },
    { image: "/images/comercial/pan38.webp", category: "events" },
    { image: "/images/comercial/pan39.webp", category: "events" },
    { image: "/images/comercial/pan40.webp", category: "events" },
    { image: "/images/comercial/pan41.webp", category: "events" },
    { image: "/images/comercial/pan42.webp", category: "events" },
    { image: "/images/comercial/pan43.webp", category: "events" },
    { image: "/images/comercial/pan44.webp", category: "events" },
    { image: "/images/comercial/pan45.webp", category: "events" },
    { image: "/images/comercial/pan46.webp", category: "events" },
    { image: "/images/comercial/pan47.webp", category: "events" },
    { image: "/images/comercial/pan48.webp", category: "events" },
    { image: "/images/comercial/pan49.webp", category: "events" },
    { image: "/images/comercial/pan50.webp", category: "events" },
    { image: "/images/comercial/pan51.webp", category: "events" },
    { image: "/images/comercial/pan52.webp", category: "events" },
    { image: "/images/comercial/pan53.webp", category: "events" },
    { image: "/images/comercial/pan54.webp", category: "events" },
    { image: "/images/comercial/pan55.webp", category: "events" },
    { image: "/images/comercial/pan56.webp", category: "events" },
    { image: "/images/comercial/pan57.webp", category: "events" },
    { image: "/images/comercial/pan58.webp", category: "events" },
    { image: "/images/comercial/pan59.webp", category: "events" },
    { image: "/images/comercial/pan60.webp", category: "events" },
    { image: "/images/comercial/pan61.webp", category: "events" },
    { image: "/images/comercial/pan62.webp", category: "events" },
    { image: "/images/comercial/pan63.webp", category: "events" }
];



  const categories = [
    { name: 'All', filter: 'All' },
    { name: 'Weddings Moments', filter: 'wedding' },
    { name: 'Lifestyle', filter: 'lifestyle' },
    { name: 'Engagement', filter: 'engagements' },
    { name: "KIDS' MOMENTS", filter: 'kids' },
    { name: 'Car Shows & More', filter: 'cars' },
    { name: 'Event Highlights', filter: 'events' },
    { name: 'Nightlife Hotspots', filter: 'nightlife' },
    { name: 'Maternity', filter: 'maternity' },
    { name: 'Hotel & Dining Spaces', filter: 'spaces' },
  ];

  const filteredCollage = selectedCategory === 'All'
    ? collage
    : collage.filter(item => item.category.toLowerCase() === selectedCategory.toLowerCase());

  return (
    <>
     
      <div className={style.collageFilter}>
      <h2 className={style.categoryHeading}>Select Category:</h2>
      <select 
        value={selectedCategory} 
        onChange={handleCategoryChange} 
        className={style.dropdown}
      >

        {categories.map((cat, index) => (
          <option 
          className={style.dropdownOption}
          
            key={index} 
            value={cat.filter}
          >
            {cat.name}
          </option>
        ))}
      </select>
    </div>

      <div className={style.collageDis}>
        {filteredCollage.map((item, index) => (
           <div data-aos="fade-up" key={index}>
          <LazyLoad height={200} offset={100}>
            
              <img src={item.image} alt={item.category} className={style.collageImg} />
            
          </LazyLoad>
             </div>
        ))}
      </div>
      <Slider />
    </>
  );
};

export default YourComponent;
