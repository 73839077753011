import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Spaces = () =>{
  const collage = [
    { image: "/images/spaces/event1.webp", category: "spaces" },
    { image: "/images/spaces/event2.webp", category: "spaces" },
    { image: "/images/spaces/event3.webp", category: "spaces" },
    { image: "/images/spaces/event4.webp", category: "spaces" },
    { image: "/images/spaces/event5.webp", category: "spaces" },
    { image: "/images/spaces/event6.webp", category: "spaces" },
    { image: "/images/spaces/event7.webp", category: "spaces" },
    { image: "/images/spaces/event8.webp", category: "spaces" },
    { image: "/images/spaces/event9.webp", category: "spaces" },
    { image: "/images/spaces/event10.webp", category: "spaces" },
    { image: "/images/spaces/event11.webp", category: "spaces" },
    { image: "/images/spaces/event12.webp", category: "spaces" },
    { image: "/images/spaces/event13.webp", category: "spaces" },
    { image: "/images/spaces/event14.webp", category: "spaces" },
    { image: "/images/spaces/event15.webp", category: "spaces" },
    { image: "/images/spaces/event16.webp", category: "spaces" },
    { image: "/images/spaces/event17.webp", category: "spaces" },
    { image: "/images/spaces/event18.webp", category: "spaces" },
    { image: "/images/spaces/event19.webp", category: "spaces" },
    { image: "/images/spaces/event20.webp", category: "spaces" },
    { image: "/images/spaces/event21.webp", category: "spaces" },
    { image: "/images/spaces/event22.webp", category: "spaces" },
    { image: "/images/spaces/event23.webp", category: "spaces" },
    { image: "/images/spaces/event24.webp", category: "spaces" },
  
 
];

      return(
          <>
        
              <Portfolio collage={collage}  title="Hotel & Dining Spaces" text="Discover elegant images of hotel and dining interiors, showcasing the refined design and inviting ambiance of these spaces."/>
              <Slider/>
              </>
      )
  }
  

export default Spaces;