import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay } from "swiper/modules";
import style from "./slider.module.css";
import LazyLoad from "react-lazyload";

const Slider = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  const slider = [
    {
      image:"/images/modeling/model17.webp",
     
    },
    {
      image:"/images/nightlife/night13.webp",
     
    },
    {
      image:"/images/kids/kid1.webp",
     
    },
    {
      image:"/images/weddings/wedp14.webp",
    
    },
    {
      image:"/images/cars/car1.webp",
     
    },
    {
      image:"/images/comercial/pan4.webp",
    
    },
    {
      image:"/images/modeling/model18.webp",
     
    },
    {
      image:"/images/kids/kid2.webp",
     
    },
    {
      image:"/images/nightlife/night21.webp",
     
    },
    {
      image:"/images/comercial/pan57.webp",
    
    },
    {
      image:"/images/weddings/wedp15.webp",

    },
    {
      image:"/images/kids/kid43.webp",
     
    },
    {
      image:"/images/modeling/model19.webp",
   
    },
    {
      image:"/images/comercial/pan15.webp",
    
    },
    {
      image:"/images/cars/car4.webp",
     
    },
    {
      image:"/images/kids/kid26.webp",
     
    },
    {
      image:"/images/nightlife/night2.webp",
     
    },
    {
      image:"/images/weddings/wedp16.webp",
    
    },
    {
      image:"/images/modeling/model20.webp",
     
    },
    {
      image:"/images/comercial/pan47.webp",
    
    },
    {
      image:"/images/kids/kid4.webp",
     
    },
    {
      image:"/images/cars/car7.webp",
     
    },
    {
      image:"/images/cars/car11.webp",

    },
    {
      image:"/images/weddings/wedp17.webp",
   
    },
    {
      image:"/images/kids/kid53.webp",
     
    },
    {
      image:"/images/comercial/pan30.webp",
    
    },
    {
      image:"/images/modeling/model21.webp",
     
    },
    {
      image:"/images/cars/car19.webp",
     
    },
    {
      image:"/images/comercial/pan12.webp",
    
    },
    {
      image:"/images/kids/kid55.webp",
     
    },
    {
      image:"/images/cars/car14.webp",
    
    },
    {
      image:"/images/comercial/pan3.webp",
    
    },
    {
      image:"/images/weddings/wedp31.webp",
     
    },
   
    {
      image:"/images/nightlife/night8.webp",
     
    },
    {
      image:"/images/weddings/wedp32.webp",
   
    },
    {
      image:"/images/comercial/pan10.webp",
    
    },
    {
      image:"/images/modeling/model34.webp",
     
    },
    {
      image:"/images/kids/kid9.webp",
     
    },
    {
      image:"/images/comercial/pan13.webp",
    
    },
    {
      image:"/images/nightlife/night11.webp",
     
    },
    {
      image:"/images/nightlife/night12.webp",
     
    },
    {
      image:"/images/modeling/model35.webp",
     
    },
    {
      image:"/images/comercial/pan36.webp",
    
    },
    {
      image:"/images/nightlife/night9.webp",
     
    },
    {
      image:"/images/kids/kid28.webp",
     
    },
    {
      image:"/images/comercial/pan52.webp",
    
    },
    {
      image:"/images/weddings/wedp33.webp",

    },
    {
      image:"/images/modeling/model25.webp",
   
    },
    {
      image:"/images/modeling/model26.webp",
     
    },
    {
      image:"/images/comercial/pan58.webp",
    
    },
    {
      image:"/images/weddings/wedp34.webp",

    },
    {
      image:"/images/kids/kid37.webp",
     
    },
    {
      image:"/images/weddings/wedp35.webp",

    },
    {
      image:"/images/cars/car13.webp",

    },
    {
      image:"/images/comercial/pan14.webp",
    
    },
    {
      image:"/images/cars/car18.webp",

    },
  ];
  return (
 
    <div data-aos="fade-up">
      <div className={`container ${style.sliderHeader}`}>
        <h2>
        I'D LOVE TO HEAR FROM YOU.
       
        </h2>
        <p>FOR SESSION INFO/AVAILABILITY PLEASE REACH OUT HERE & I'LL GET RIGHT BACK TO YOU.</p>
        <a href="https://www.instagram.com/medirushiti/" target="_blank">@MEDIRUSHITI</a>
      </div>

      <Swiper
        watchSlidesProgress={true}
        autoplay={{
          delay: 0, // Set the autoplay delay to 5000ms (5 seconds)
        }}
        className="mySwiper pt-100"
        modules={[Autoplay]}
        centeredSlides={true}
        loop={true}
        speed={5000}
        breakpoints={{
          320: {
            slidesPerView: 1.2,
          },
          600: {
            slidesPerView: 1.2,
          },
          700: {
            slidesPerView: 2,
          },
          800: {
            slidesPerView: 2,
          },
          900: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 4.5,
          },
        }}
      >
        {slider.map((item, index) => {
          return (
            <SwiperSlide
              className={style.sliderPosition}
              key={`slider-${item.image}`}
            >
              <LazyLoad height={200} offset={100}  >
              <a href="https://www.instagram.com/medirushiti/" target="_blank"  rel="noreferrer">
                <img src={item.image} className="w-100" width="100%" alt="ig"/>
              </a>
              </LazyLoad>
        
            </SwiperSlide>
          );
        })}
      </Swiper>
      </div>
  );
};

export default Slider;
