import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Lifestyle = () =>{
  const collage = [
    { image: "/images/modeling/model49.webp" },
    { image: "/images/modeling/model1.webp" },
    { image: "/images/modeling/model2.webp" },
    { image: "/images/modeling/model3.webp" },
    { image: "/images/modeling/model4.webp" },
    { image: "/images/modeling/model5.webp" },
    { image: "/images/modeling/model6.webp" },
    { image: "/images/modeling/model7.webp" },
    { image: "/images/modeling/model8.webp" },
    { image: "/images/modeling/model9.webp" },
    { image: "/images/modeling/model10.webp" },
    { image: "/images/modeling/model11.webp" },
    { image: "/images/modeling/model12.webp" },
    { image: "/images/modeling/model13.webp" },
    { image: "/images/modeling/model14.webp" },
    { image: "/images/modeling/model15.webp" },
    { image: "/images/modeling/model16.webp" },
    { image: "/images/modeling/model17.webp" },
    { image: "/images/modeling/model18.webp" },
    { image: "/images/modeling/model19.webp" },
    { image: "/images/modeling/model20.webp" },
    { image: "/images/modeling/model21.webp" },
    { image: "/images/modeling/model22.webp" },
    { image: "/images/modeling/model23.webp" },
    { image: "/images/modeling/model24.webp" },
    { image: "/images/modeling/model25.webp" },
    { image: "/images/modeling/model26.webp" },
    { image: "/images/modeling/model27.webp" },
    { image: "/images/modeling/model28.webp" },
    { image: "/images/modeling/model29.webp" },
    { image: "/images/modeling/model30.webp" },
    { image: "/images/modeling/model31.webp" },
    { image: "/images/modeling/model32.webp" },
    { image: "/images/modeling/model33.webp" },
    { image: "/images/modeling/model34.webp" },
    { image: "/images/modeling/model35.webp" },
    { image: "/images/modeling/model36.webp" },
    { image: "/images/modeling/model37.webp" },
    { image: "/images/modeling/model38.webp" },
    { image: "/images/modeling/model39.webp" },
    { image: "/images/modeling/model40.webp" },
    { image: "/images/modeling/model41.webp" },
    { image: "/images/modeling/model42.webp" },
    { image: "/images/modeling/model43.webp" },
    { image: "/images/modeling/model44.webp" },
    { image: "/images/modeling/model45.webp" },
    { image: "/images/modeling/model46.webp" },
    { image: "/images/modeling/model47.webp" },
  
 
];

      return(
          <>
        
              <Portfolio collage={collage}  title="LIFESTYLE" text="Exploring the essence of everyday moments, I specialize in capturing authentic and vibrant photographs that reflect your unique lifestyle. Let's create images that resonate with your personality and tell your story beautifully."/>
              <Slider/>
              </>
      )
  }
  

export default Lifestyle;