import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Engagments = () =>{
    const collage = [
        {
            image:"/images/kids.webp",
           
          },
          {
            image:"/images/kid2.webp",
           
          },
          {
              image:"/images/kid3.webp",
             
            },
      
          
            {
              image:"/images/kid4.webp",
        
            },
            {
              image:"/images/kids2.webp",
           
            },
            {
                image:"/images/kid5.webp",
             
              },
              {
                image:"/images/c3.webp",
               
              },
            
          
                  {
                    image:"/images/kkid.webp",
                  
                  },
                  {
                    image:"/images/c4.webp",
                  
                  },
           
        
      ]
      return(
          <>
        
              <Portfolio collage={collage}  title="ENGAGMENTS" text="From the first embrace to the promises of forever, I specialize in capturing the genuine emotions and excitement of your engagement. Let's create stunning photographs that celebrate your love and anticipation as you begin this beautiful journey together."/>
              <Slider/>
              </>
      )
  }
  

export default Engagments;