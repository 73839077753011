import React from "react";
import style from "./Weddings.module.css"
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";

const Weddings = () =>{
  const collage = [
    { image: "/images/weddings/wedp1.webp" },
    { image: "/images/weddings/wedp2.webp" },
    { image: "/images/weddings/wedp3.webp" },
    { image: "/images/weddings/wedp4.webp" },
    { image: "/images/weddings/wedp5.webp" },
    { image: "/images/weddings/wedp6.webp" },
    { image: "/images/weddings/wedp7.webp" },
    { image: "/images/weddings/wedp8.webp" },
    { image: "/images/weddings/wedp9.webp" },
    { image: "/images/weddings/wedp10.webp" },
    { image: "/images/weddings/wedp11.webp" },
    { image: "/images/weddings/wedp12.webp" },
    { image: "/images/weddings/wedp13.webp" },
    { image: "/images/weddings/wedp14.webp" },
    { image: "/images/weddings/wedp15.webp" },
    { image: "/images/weddings/wedp16.webp" },
    { image: "/images/weddings/wedp17.webp" },
    { image: "/images/weddings/wedp18.webp" },
    { image: "/images/weddings/wedp19.webp" },
    { image: "/images/weddings/wedp20.webp" },
    { image: "/images/weddings/wedp21.webp" },
    { image: "/images/weddings/wedp22.webp" },
    { image: "/images/weddings/wedp23.webp" },
    { image: "/images/weddings/wedp24.webp" },
    { image: "/images/weddings/wedp25.webp" },
    { image: "/images/weddings/wedp26.webp" },
    { image: "/images/weddings/wedp27.webp" },
    { image: "/images/weddings/wedp28.webp" },
    { image: "/images/weddings/wedp29.webp" },
    { image: "/images/weddings/wedp30.webp" },
    { image: "/images/weddings/wedp31.webp" },
    { image: "/images/weddings/wedp32.webp" },
    { image: "/images/weddings/wedp33.webp" },
    { image: "/images/weddings/wedp34.webp" },
    { image: "/images/weddings/wedp35.webp" },
    { image: "/images/weddings/wedp36.webp" }
];

    return(
        <>
      
            <Portfolio collage={collage} title="WEDDINGS" text="Preserving every heartfelt moment and romantic detail, I specialize in crafting timeless photographs that capture the love and magic of your special day. Let me tell your unique love story through the lens, creating memories that will last a lifetime."/>
            <Slider/>
            </>
    )
}

export default Weddings