import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Cars = () =>{
  const collage = [
    { image: "/images/cars/car1.webp", category: "cars" },
    { image: "/images/cars/car2.webp", category: "cars" },
    { image: "/images/cars/car3.webp", category: "cars" },
    { image: "/images/cars/car4.webp", category: "cars" },
    { image: "/images/cars/car5.webp", category: "cars" },
    { image: "/images/cars/car6.webp", category: "cars" },
    { image: "/images/cars/car7.webp", category: "cars" },
    { image: "/images/cars/car8.webp", category: "cars" },
    { image: "/images/cars/car9.webp", category: "cars" },
    { image: "/images/cars/car10.webp", category: "cars" },
    { image: "/images/cars/car11.webp", category: "cars" },
    { image: "/images/cars/car12.webp", category: "cars" },
    { image: "/images/cars/car13.webp", category: "cars" },
    { image: "/images/cars/car14.webp", category: "cars" },
    { image: "/images/cars/car15.webp", category: "cars" },
    { image: "/images/cars/car16.webp", category: "cars" },
    { image: "/images/cars/car17.webp", category: "cars" },
    { image: "/images/cars/car18.webp", category: "cars" },
    { image: "/images/cars/car19.webp", category: "cars" }
];
      return(
          <>
        
              <Portfolio collage={collage}  title="Car Shows & More" text="Capturing the essence of every curve and detail, I specialize in creating stunning photographs that showcase the beauty and power of your cherished vehicle. Let's bring your car's story to life through the lens."/>
              <Slider/>
              </>
      )
  }
  

export default Cars;