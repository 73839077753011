import React, { useState, useEffect } from 'react';
import { Pivot as Hamburger } from 'hamburger-react'
  

import style from './Header.module.css';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }
  }, [isOpen]);

  const location = useLocation();
  const handleClick = () => {
    setOpen(false);
      window.scrollTo(0, 0);;
  };
  return (
    <nav className={`${style.navbar} ${scrolled ? style.navbarScrolled : ''}`}>
      <div className={` ${style.navDis}`}>
        <div
          className={`imageColor ${style.navbarLogo} ${location.pathname !== '/' ? style.whiteLogo : ''}`}
          onClick={() => {
              window.scrollTo(0, 0);;
          }}
        >
          <Link to="/"  onClick={handleClick}><img src='/images/logo.webp' alt="Logo" /></Link>
        </div>
        <div className={style.navbarMenu}>
       
          <div className={`hambColor ${style.hamburger} ${location.pathname !== '/' ? style.hambColor : ""}`}>
          
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </div>
          <div className={`itemsColor ${style.navbarItems} ${location.pathname !== '/' ? style.whiteItems : ''} ${isOpen ? style.showMenu : ''}`}>
     
          <div className={style.blob}>
        

          </div>
           <div className={style.mobileItem}>
           <Link  to="/" onClick={handleClick}>Home </Link>
            <Link to="/portfolio" onClick={handleClick}>Portfolio</Link>
        
            <Link to="/contact" onClick={handleClick}>Contact </Link>
            <Link to="/about" onClick={handleClick}>About </Link>
            <img src='/images/weddings/wedp5.webp' className={style.mobilebck}/>
            <div className={style.iconDis}>
           <a href="mailto:medirushitiphoto@gmail.com">
            <img src="/images/emailcircle.png"/>
            </a>
            <a href="tel:+38343972387">
              <img src="/images/phonecircle.png"/>
              </a>
              <a href="https://www.youtube.com/@MediRushiti">
              <img src="/images/youtube.png"/>
              </a>

              <a href="https://www.instagram.com/medirushiti/">
              <img src="/images/insta.png"/>
              </a>
            </div>
           </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
