import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const NightLife = () =>{
  const collage = [
    { image: "/images/nightlife/night1.webp", category: "nightlife" },
    { image: "/images/nightlife/night2.webp", category: "nightlife" },
    { image: "/images/nightlife/night3.webp", category: "nightlife" },
    { image: "/images/nightlife/night4.webp", category: "nightlife" },
    { image: "/images/nightlife/night5.webp", category: "nightlife" },
    { image: "/images/nightlife/night6.webp", category: "nightlife" },
    { image: "/images/nightlife/night7.webp", category: "nightlife" },
    { image: "/images/nightlife/night8.webp", category: "nightlife" },
    { image: "/images/nightlife/night9.webp", category: "nightlife" },
    { image: "/images/nightlife/night10.webp", category: "nightlife" },
    { image: "/images/nightlife/night11.webp", category: "nightlife" },
    { image: "/images/nightlife/night12.webp", category: "nightlife" },
    { image: "/images/nightlife/night13.webp", category: "nightlife" },
    { image: "/images/nightlife/night14.webp", category: "nightlife" },
    { image: "/images/nightlife/night15.webp", category: "nightlife" },
    { image: "/images/nightlife/night16.webp", category: "nightlife" },
    { image: "/images/nightlife/night17.webp", category: "nightlife" },
    { image: "/images/nightlife/night18.webp", category: "nightlife" },
    { image: "/images/nightlife/night19.webp", category: "nightlife" },
    { image: "/images/nightlife/night20.webp", category: "nightlife" },
    { image: "/images/nightlife/night21.webp", category: "nightlife" },
    { image: "/images/nightlife/night22.webp", category: "nightlife" },
    { image: "/images/nightlife/night23.webp", category: "nightlife" },
 
];

      return(
          <>
        
              <Portfolio collage={collage}  title="Nightlife Hotspots" text="Dive into vibrant nightlife with our collection of dynamic photos capturing the energy and excitement of evening scenes, from lively clubs to stylish bars."/>
              <Slider/>
              </>
      )
  }
  

export default NightLife;